<template>

    <main id="Header1" class="container">
        <div class="container">
            

            

            
        </div>


    </main>    
</template>


<script>
export default {
    name:'Header1',

    data:function(){
        return{
           //
        }
    },

    methods:{
        

        

       //

    },
}
</script>


<style>

   

</style>

